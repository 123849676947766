// apiService.js
import { apiClientDoctor , apiClient} from "./apiClient";
// Example API functions
const apiService = {
  //  Admin Login
  adminLogin: (data) => apiClient.post("/superadminlogin", data),
  //  Register Facility
  registerFacility: (facilityData) =>
    apiClient.post("/createFacility", facilityData, {
      Headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  // get All facility
  getAllFacilities: () => apiClient.post("/getAllFacility"),
  // get update facility
  updateFacility: (data) => apiClient.post("/updateFacility", data),
  // get All Speciality
  getAllSpeciality: () => apiClient.get("/getSpecialist"),
  //   Verify Phone Number
  sendOtpVerifyNumber: (data) =>
    apiClient.post(`/validate-register-mobile`, data),
  //  verify otp
  verifyOtp: (otpData) => apiClient.post(`/validate-otp`, otpData),
  //  Get Parent Facility
  getParentsFacilities: () => apiClient.post("/getAllFacility"),

  //  Register Patient
  registerPatient: (data) =>
    apiClient.post("/registerPatient", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  Get All Patients
  getAllPatients: () => apiClient.get("/getAllPatients"),

  //  update Patient
  updatePatient: (data) =>
    apiClient.post("/updatePatient", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  //  Get Doctor List
  getDoctorList: (data) => apiClient.post("/getDoctorList", data),
  //  Register Doctor
  registerDoctor: (data) => apiClient.post("/registerDoctor", data),
  updateDoctor: (data) => apiClient.post("/updateDoctor", data),

  //  Get All Facility Staff
  getAllFacilityStaff: (data) => apiClient.post(`/getAllFacilityStaff`, data),
  //  Register Facility Staff
  registerFacilityStaff: (staffData) =>
    apiClient.post(`/addFacilityStaff`, staffData, {
      Headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  update Facility Staff
  updateFacilityStaff: (staffData) =>
    apiClient.post(`/updateFacilityStaff`, staffData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  //  Get All Specialization
  getAllSpecializations: () => apiClient.post(`/getAllSpecializations`),
  createRecord :(data)=> apiClient.post("/createRecord",data),

  searchPatient : (data)=> apiClientDoctor.post("/api/v1/patient/searchPatient",data),
  
};

export default apiService;
