// Validation for Name
export const validateName = (name) => {
  
  if (!name || name.trim() === "") {
    return "Name is required";
  } else if (!/^[a-zA-Z ]+$/.test(name)) {
    return "Name can only contain letters and spaces";
  }
  return ""; // No error
};

export const validateNameButNotRequired = (name) => {
  if (name.length == 0) {
    return ""; // No error for empty string email
  }
 else if (!/^[a-zA-Z ]+$/.test(name)) {
    return "Name can only contain letters and spaces";
  }
  return ""; // No error
};

// Validation for Phone Number
export const validatePhone = (phone) => {
  if (!phone || phone.trim() === "") {
    return "Phone number is required";
  } else if (!/^\d{10}$/.test(phone)) {
    return "Phone number must be 10 digits";
  }
  return ""; // No error
};
// Validation for Phone Number
export const validatePhoneButNotRequired = (phone) => {
  if (phone.length == 0) {
    return ""; // No error for empty string email
  }
 else if (!/^\d{10}$/.test(phone)) {
    return "Phone number must be 10 digits";
  }
  return ""; // No error
};

// Validation for Email
export const validateEmail = (email) => {
  if (!email || email.trim() === "") {
    return "Email is required";
  } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
    return "Invalid email format";
  }
  return ""; // No error
};
// Validation for Email
export const validateEmailButNotRequired = (email) => {

  let email1 = email.trim();
  if (email1.length == 0) {
    return ""; // No error for empty string email
  } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email1)) {
    return "Invalid email format";
  }
  return ""; // No error
};

// Validation for Password
export const validatePassword = (password) => {
  if (!password || password.trim() === "") {
    return "Password is required";
  } else if (password.length < 6) {
    return "Password must be at least 6 characters long";
  }
  return ""; // No error
};

// Validation for Password
export const validatePasswordButNotRequired = (password) => {
  if (password.length == 0) {
    return ""; // No error for empty string email
  }
else  if (password.length < 6) {
    return "Password must be at least 6 characters long";
  }
  return ""; // No error
};

// Validation for Date of Birth (cannot select day after today)
export const validateDOB = (dob) => {
  const today = new Date();
  const selectedDate = new Date(dob);

  if (!dob) {
    return "Date of birth is required";
  } else if (selectedDate > today) {
    return "Date of birth cannot be in the future";
  }
  return ""; // No error
};

// Validation for Appointment Date (cannot select a past date)
export const validateAppointmentDate = (appointmentDate) => {
  const today = new Date();
  const selectedDate = new Date(appointmentDate);

  // Ignore the time part, only compare the date
  today.setHours(0, 0, 0, 0);
  selectedDate.setHours(0, 0, 0, 0);

  if (!appointmentDate) {
    return "Appointment date is required";
  } else if (selectedDate < today) {
    return "Appointment date cannot be in the past";
  }
  return ""; // No error
};
