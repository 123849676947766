import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  useNavigate,
  Link,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import Swal from "sweetalert2";
import { validateName } from "../../../Validation";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Avatar,
  Grid,
  Divider,
} from "@mui/material";
import apiService from "../../../api/apiService";
 import { useSuperAdmin } from "../../context/SuperAdminContext";
const UploadRecord = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const recorddetails = location?.state?.appointment;
   const {superAdmin} = useSuperAdmin();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [patient, setPatient] = useState({
    patient_id: "",
    patientName: "",
  });
  const [formData, setFormData] = useState({
    userid: "",
    recordname: "",
    doctorname: "",
    typeofrecord: "",
    facultyname: "",
    feetype: "",
    amount: "",
  });
  const [errors, setErrors] = useState({
    recordname: "",
    doctorname: "",
    typeofrecord: "",
    facultyname: "",
    feetype: "",
    amount: "",
  });
  useEffect(() => {
    setFormData({
      userid: recorddetails?.patientid,
      recordname: recorddetails?.recordname || "",
      doctorname: recorddetails?.doctorname || "",
      // facultyname: SubAdmin?.clinicName || "",
    });
    setPatient({
      patient_id: recorddetails?.patientid || "",
      patientName: recorddetails?.patientname || "",
    });
  }, [recorddetails]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileError(""); // Clear previous errors
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 2 * 1024 * 1024; // 2 MB

      if (!validTypes.includes(file.type)) {
        setFileError("Only image files (JPEG, PNG, GIF) are allowed.");
      } else if (file.size > maxSize) {
        setFileError("File size must be less than 2 MB.");
      } else {
        setSelectedFile(file);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    switch (name) {
      case "recordname":
        setErrors({ ...errors, recordname: validateName(value) });
        break;
      case "doctorname":
        setErrors({ ...errors, doctorname: validateName(value) });
        break;
      case "facultyname":
        setErrors({ ...errors, facultyname: validateName(value) });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const finalData = new FormData();
      Object.keys(formData).forEach((key) => {
        finalData.append(key, formData[key]);
      });
      finalData.append("attachment", selectedFile);

      const response = await apiService.createRecord(finalData);

      if (response.status === 200) {
        Swal.fire({
          title: "Record Added Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1200,
        });
        navigate("/allpatients");
      }

      setFormData({
        // userid: user.id,
        recordname: "",
        doctorname: "",
        typeofrecord: "",
        facultyname: "",
        feetype: "",
        amount: "",
      });
      setSelectedFile(null); // Clear the file input after successful submission
    } catch (error) {
      console.error(error);
      toast.error("Error uploading the record. Please try again.");
    }
  };

  return (
    <div className="container">
      <Box
        p={1}
        sx={{ backgroundColor: "#f9f9f9", borderRadius: 2, boxShadow: 2 }}
      >
        <Typography variant="h6">Upload Record </Typography>
        <Typography variant="subtitle2" align="" gutterBottom>
          ( Patient Name: {patient.patientName} | Patient Id:{" "}
          {patient.patient_id})
        </Typography>
        <Divider sx={{ bgcolor: "purple", p: 0, m: 0 }} />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                label="Record Name"
                name="recordname"
                required
                value={formData.recordname}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.recordname}
                helperText={errors.recordname}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                label="Doctor Name"
                name="doctorname"
                required
                value={formData.doctorname}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.doctorname}
                helperText={errors.doctorname}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth margin="normal">
                {/* <InputLabel size="small">Type of Record</InputLabel> */}
                <TextField
                  select
                  size="small"
                  name="typeofrecord"
                  value={formData.typeofrecord}
                  onChange={handleChange}
                  required
                  id="outlined-select-currency"
                  label="Type of Record"
                >
                  <MenuItem value="">Select Type</MenuItem>
                  <MenuItem value="PRESCRIPTION">Prescription</MenuItem>
                  <MenuItem value="TESTRESULTS">Test Results</MenuItem>
                  <MenuItem value="EXPENSES">Expenses</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                label="Facility Name"
                name="facultyname"
                required
                value={formData.facultyname}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.facultyname}
                helperText={errors.facultyname}
              />
            </Grid>
            {formData.typeofrecord === "EXPENSES" && (
              <>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      value={formData.feetype}
                      size="small"
                      name="feetype"
                      onChange={handleChange}
                      id="outlined-select-currency"
                      select
                      label="Types of Fees"
                    >
                      <MenuItem value="Doctor fees">Doctor's Fees</MenuItem>
                      <MenuItem value="Lab fees">Lab Fees</MenuItem>
                      <MenuItem value="Medicine fees">Medicine Fees</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    size="small"
                    label="Amount"
                    type="number"
                    name="amount"
                    required
                    value={formData.amount}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={2} alignItems="center" mt={0}>
            <Grid item xs={6} md={3}>
              <Button variant="contained" component="label" fullWidth>
                Upload File
                <input type="file" hidden onChange={handleFileChange} />
              </Button>
              {fileError && (
                <Typography color="error" variant="caption">
                  {fileError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={3}>
              <Avatar
                src={
                  selectedFile
                    ? URL.createObjectURL(selectedFile)
                    : "https://via.placeholder.com/100"
                }
                variant="rounded"
                sx={{ width: 100, height: 100 }}
              />
            </Grid>
          </Grid>

          <Box mt={3} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" type="submit">
              Create Record
            </Button>
          </Box>
        </form>
      </Box>
      <ToastContainer position="top-center" theme="light" />
    </div>
  );
};

export default UploadRecord;
