import React, { createContext, useContext, useState, useEffect } from "react";
import apiService from "../../api/apiService";
// Create SuperAdmin Context
const SuperAdminContext = createContext();

export const SuperAdminProvider = ({ children }) => {
  // Initialize state from sessionStorage or with default values
  const [superAdmin, setSuperAdmin] = useState({
    id: "",
    name: "",
    mobilenumber: "",
    emailid: "",
    role: "",
  });
   useEffect(()=>{
    
    getSuperAdminInfo();

   },[])
    const getSuperAdminInfo=async()=>{
      try{
        // const res = await apiService.adminLogin();
        //   setSuperAdmin( res.data.data);
      }catch(error){
        console.error("Error getting superAdmin data from sessionStorage", error);
      }
    }

  return (
    <SuperAdminContext.Provider value={{ superAdmin, setSuperAdmin }}>
      {children}
    </SuperAdminContext.Provider>
  );
};
export const useSuperAdmin = () => useContext(SuperAdminContext);
