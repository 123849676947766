import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./superadmin/components/loader/Loader";
import Scrolltop from "./superadmin/components/ScrollToTop";
import "./App.css";

import PrivateRoute from "./auth/PrivateRoute";
import UploadRecord from "./superadmin/components/pages/UploadRecord";
const LoginWithPassword = lazy(() => import("./auth/LoginWithPassword"));

const AdminDashboard = lazy(() =>
  import("./superadmin/components/pages/AdminDashbaord")
);

const DoctorManagement = lazy(() =>
  import("./superadmin/components/pages/AllDocotorList")
);
const AddNewPatientpoly = lazy(() =>
  import("./superadmin/components/pages/AddNewPatient")
);

const DocRegister = lazy(() =>
  import("./superadmin/components/pages/DoctorRegistrationForm")
);

const EditPatientByClinic = lazy(() =>
  import("./superadmin/components/pages/EditPatient")
);
const EditDoctorByClinic = lazy(() =>
  import("./superadmin/components/pages/EditDoctorProfilePoly")
);
const AllClinic = lazy(() =>
  import("./superadmin/components/pages/AllFacility")
);
const AddClinic = lazy(() =>
  import("./superadmin/components/pages/AddFacility")
);
const EditClinic = lazy(() =>
  import("./superadmin/components/pages/EditFacility")
);
const AllPatientsLIst = lazy(() =>
  import("./superadmin/components/pages/AllPatientsList")
);
const Dashboard = lazy(() =>
  import("./superadmin/components/layout/Dashboard")
);
const Adminuser = lazy(() => import("./superadmin/components/pages/AdminUser"));

const AllClinicStaff = lazy(() =>
  import("./superadmin/components/pages/AllFacilityStaff")
);
const AddClinicStaff = lazy(() =>
  import("./superadmin/components/pages/FacilityStaffRegistration")
);
const EditStaff = lazy(() =>
  import("./superadmin/components/pages/EditFacilityStaff")
);
const RegisterAdmin = lazy(() =>
  import("./superadmin/components/pages/AdminRegistrationForm")
);

const AddClinic2 = lazy(() =>
  import("./superadmin/components/pages/AddFacility2")
);
const EditClinic2 = lazy(() =>
  import("./superadmin/components/pages/EditFacility2")
);

const AllLabtest = lazy(() =>
  import("./superadmin/components/pages/AllLabtest")
);
const AllSymptoms = lazy(() =>
  import("./superadmin/components/pages/NewData")
);
const uplaodRecord = lazy(()=>import("./superadmin/components/pages/UploadRecord"));
function App() {
  return (
    <BrowserRouter>
      <Scrolltop />
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* <Route path="/login/otp" element={<LoginWithOtp />} /> */}
          <Route path="/" element={<LoginWithPassword />} />

          {/*  Polly clicnic Routes  */}
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="doctormanagement" element={<DoctorManagement />} />
              <Route path="addnewpatientpoly" element={<AddNewPatientpoly />} />

              <Route path="docregister" element={<DocRegister />} />

              <Route
                path="editpatientbyclinic"
                element={<EditPatientByClinic />}
              />
              <Route
                path="editdoctorbyclinic"
                element={<EditDoctorByClinic />}
              />
              <Route path="allclinic" element={<AllClinic />} />
              <Route path="addclinic" element={<AddClinic2 />} />
              <Route path="editclinic" element={<EditClinic2 />} />
              <Route path="allpatients" element={<AllPatientsLIst />} />
              <Route path="adminuser" element={<Adminuser />} />
              <Route path="allclinicstaff" element={<AllClinicStaff />} />
              <Route path="addclinicstaff" element={<AddClinicStaff />} />
              <Route path="editstaff" element={<EditStaff />} />
              <Route path="registeradmin" element={<RegisterAdmin />} />
              <Route path="addclinic2" element={<AddClinic />} />
              <Route path="editclinic2" element={<EditClinic />} />
              <Route path="alllabtest" element={<AllLabtest />} />
              <Route path="allsymptoms" element={<AllSymptoms />} />
              <Route path="uploadrecord" element={<UploadRecord />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
